.navigation {
  a {
    position: fixed;
    font-size: 3em;
    z-index: 999;
    opacity: .75;
    transition: .3s;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 1em;

    @media screen and (max-width: $tablet) {
      padding: 0 .25em;
    }

    &:hover {
      opacity: 1;
    }

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }
}