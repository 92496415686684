.case-block {
  padding-bottom: 6em;
}

.display-1 {
  @media screen and (min-width: $tablet + 1) {
    height: 100vh;
    width: 100%;

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    transform: none !important;
    opacity: 1 !important;
    display: none;
  }
}

.mob-bg {
  width: 100%;

  @media screen and (min-width: $tablet + 1) {
    display: none;
  }
}

.display-2 {
  margin-top: -183/16 + em;
  position: relative;
  text-align: center;

  @media screen and (max-width: $tablet) {
    margin-top: 0;
    transition: 1s linear;
  }

  .container {
    max-width: 1780px;
    position: relative;
    padding: 0;

    @media screen and (max-width: 1560px) {
      max-width: 1110px;
    }
  }

  img:not(.mac) {
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: 79%;
    height: 90.3%;
    object-fit: cover;
    border-radius: 2.2vw 2.2vw .8vw .8vw;

    @media screen and (max-width: $tablet) {
      border-top-right-radius: 5vw;
      border-top-left-radius: 5vw;
    }
  }
}

.text-block {
  text-align: center;
  font-weight: bold;
  font-size: 1.375em;
  margin: 4em 0;
  text-transform: uppercase;
  line-height: normal;
  pointer-events: none;

  .container {
    max-width: 980/22 + em;
  }

  p {
    margin: 0 auto 1em;
  }
}

.display-3 {
  overflow-x: hidden;
  padding-top: 5em;

  @media screen and (max-width: $tablet) {
    padding-top: 0;
  }

  .container div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6em;

    @media screen and (max-width: $tablet) {
      flex-wrap: wrap;
    }

    .text {
      max-width: 35%;
      transform: scale(.75);
      transition: .75s linear;
      opacity: 0;

      @media screen and (max-width: $tablet) {
        max-width: 100%;
        max-width: 20em;
        max-height: 12em;
        display: block;
        margin: 0 auto 5em;
      }
    }

    .screen {
      width: 57.4%;
      transform: translateX(100%);
      opacity: 0;
      transition: .75s linear;
      transition-delay: .3s;

      @media screen and (max-width: $tablet) {
        width: 100%;
      }

      @media screen and (max-width: $mobile-l) {
        transition-delay: 0s;
      }
    }

    &:first-child {
      direction: rtl;

      @media screen and (max-width: $tablet) {
        direction: ltr;
      }

      img:last-child {
        transform: translateX(-100%);
      }
    }

    &.animation-on {
      .text {
        opacity: 1;
        transform: scale(1);
      }

      .screen {
        transform: translateX(0) !important;
        opacity: 1;
      }
    }
  }
}

.display-4 {
  width: 100%;
  margin-bottom: 8.8em;

  @media screen and (max-width: $tablet) {
    margin-bottom: 4.8em;
  }
}

.shadow {
  //box-shadow: 0 5px 10px rgba(0, 0, 0, .35);
  box-shadow: 0 5px 30px rgba(0,0,0,.15);
}

.display-5 {
  .title {
    margin-bottom: 7.5em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 3em;
    }
  }

  img:not(.title) {
    margin-bottom: 4em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 2em;
    }
  }
}

.display-6 {
  margin-top: 7em;
  margin-bottom: 6em;
  overflow-x: hidden;

  @media screen and (max-width: $tablet) {
    margin-top: 4em;
    margin-bottom: 3em;
  }

  .title {
    margin-bottom: 6em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 2em;
    }
  }

  .container > div {
    display: flex;
    align-items: flex-start;
    margin: 0 -1em;

    @media screen and (max-width: $tablet) {
      flex-wrap: wrap;
    }

    & > div {
      padding: 1em;
      width: calc(100% / 3);

      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }
  }

  .animation-block {

    div {
      opacity: 0;
      transform: translateX(50%);
      transition: .75s;

      &:nth-of-type(2) {
        transition-delay: .25s;

        @media screen and (max-width: $mobile-l) {
          transition-delay: 0s;
        }
      }

      &:nth-of-type(3) {
        transition-delay: .5s;

        @media screen and (max-width: $mobile-l) {
          transition-delay: 0s;
        }
      }
    }

    &.animation-on {
      div {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.display-7 {
  overflow: hidden;

  .shadow-block {
    box-shadow: 0 1em 2em rgba(0, 0, 0, .1);
    position: relative;
    z-index: 1;
  }

  .title {
    margin-bottom: 6em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 3em;
    }
  }

  .grey-bg {
    overflow: hidden;
    background: #e7ebed;
    max-height: 1450/16 + em;

    @media screen and (max-width: $tablet) {
      max-height: 32.625em;
    }

    .mobile {
      display: flex;
      justify-content: center;
      margin: 0 -1.25em;
      transform: rotate(45deg);
      margin-top: -33em;

      @media screen and (max-width: $tablet) {
        margin-top: -23em;
      }

      .column {
        padding: 0 1.25em;
        transition: 1s linear;

        img {
          position: relative;
          z-index: 1;
          width: 461/16 + em;
          box-shadow: 1em 1em 3em rgba(0, 0, 0, 0.2);
          //border-radius: 4.5em;
          border-radius: 3.6vw;
        }

        &:nth-of-type(2) {
          margin-top: 38em;
        }

        &:nth-of-type(3) {
          margin-top: 19em;
        }

        div:first-child {
          margin-bottom: 2.5em;
        }

        div {
          position: relative;

          &:after {
            position: absolute;
            z-index: 0;
            //content: '';
            background: url('/assets/img/iphone-shadow.png');
            top: 0;
            left: 0;
            width: 110%;
            height: 110%;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}

.display-8 {
  padding-top: 98/16 + em;
  text-align: center;
  padding-bottom: 4em;
  box-shadow: 0 -2em 2em rgba(0, 0, 0, .05);
  position: relative;
  z-index: 1;
  overflow-x: hidden;

  .container {
    max-width: 1635px;
  }

  .title {
    margin-bottom: 113/16 + em;
    opacity: 0;
    transform: translateY(50%);
    transition: .75s;

    @media screen and (max-width: $tablet) {
      margin-bottom: 3.0625em;
    }

    &.animation-on {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $tablet) {
      flex-wrap: wrap;
    }

    & > a {
      font-size: 1.625em;
      font-weight: bold;
      transition: .3s;
      opacity: 0;
      min-width: 7em;

      @media screen and (max-width: $tablet) {
        width: 50%;
        padding: 0 1em;
        margin-bottom: 2em;

        &.hidden-lg {
          text-align: right;
        }
      }

      &:hover {
        opacity: .5;
      }

      &:first-child {
        text-align: right;
        transform: translateX(-100%);

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      &:last-child {
        text-align: left;
        transform: translateX(100%);
      }
    }

    .form {
      overflow: hidden;
      text-align: left;
      margin: 0 2em;
      width: 838/16 + em;
      max-width: 100%;
      color: #fff;
      border-radius: 10px;
      padding: (49/16 + em) (81/16 + em) (97/16 + em);
      margin-bottom: 99/16 + em;
      transform: scale(.5);
      opacity: 0;
      transition: .75s;
      position: relative;

      @media screen and (max-width: $tablet) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 4em;
        padding-right: 4em;
      }

      h3 {
        font-size: 3em;
        font-weight: bold;
        margin-top: 0;
        text-transform: uppercase;
        line-height: normal;
        margin-bottom: 1em;
      }

      & > div {
        display: table;
        width: 100%;
        transition: .5s;
        position: relative;
        z-index: 1;

        & > div {
          margin-bottom: 34/16 + em;
        }
      }

      input {
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        font-size: 1.75em;
        padding: (15/28 + em) 0;
        width: 100%;
        color: #fff;
        -webkit-appearance: none;
        border-radius: 0;

        &:focus {
          outline: none;
        }

        &:hover {
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #fff;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: #fff;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: #fff;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: #fff;
          }
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: rgba(255, 255, 255, .75);
          transition: .3s;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: rgba(255, 255, 255, .75);
          transition: .3s;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: rgba(255, 255, 255, .75);
          transition: .3s;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: rgba(255, 255, 255, .75);
          transition: .3s;
        }

        &.red {
          border-color: red;
        }
      }

      a {
        font-size: 3em;
        font-weight: bold;
        margin-top: 30/48 + em;
        text-transform: uppercase;
        line-height: normal;
        float: right;
        transition: .3s;

        @media screen and (max-width: $tablet) {
          float: none;
          display: table;
          margin: 1em auto 0;
        }

        &:hover {
          opacity: .5;
        }
      }

      .envelope {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 6em;
        margin-left: -3em;
        margin-top: -3em;
        transition: .3s, margin-top .5s;
        transform: scale(0);
        opacity: 0;
        transition-delay: .1s;
      }

      .message {
        font-size: 1.8em;
        font-weight: bold;
        text-transform: uppercase;
        transition: .3s;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: .3s;
        transition-delay: .25s;
        text-align: center;
      }

      &.animation-1 {
        & > div {
          opacity: 0;
          transform: scale(0);
        }

        .envelope {
          transform: scale(1);
          opacity: 1;
          animation: envelope infinite 1s;
          animation-delay: .5s;
        }
      }

      &.animation-2 {
        .envelope {
          margin-top: -150%;
        }

        .message {
          opacity: 1;
        }
      }

      @keyframes envelope {
        0% {
          transform: scale(1);
        }
        30% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
        70% {
          transform: scale(1);
        }
        100% {
          transform: scale(1);
        }
      }

      &.white {
        color: #000;
        background: #fff;

        input {
          color: #000;
          border-color: #000;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(0, 0, 0, .75);
            transition: .3s;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: rgba(0, 0, 0, .75);
            transition: .3s;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: rgba(0, 0, 0, .75);
            transition: .3s;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: rgba(0, 0, 0, .75);
            transition: .3s;
          }
        }
      }

      &.black {
        color: #fff;
        background: #000;

        input {
          color: #fff;
          border-color: #fff;

          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(255, 255, 255, .75);
            transition: .3s;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: rgba(255, 255, 255, .75);
            transition: .3s;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: rgba(255, 255, 255, .75);
            transition: .3s;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: rgba(255, 255, 255, .75);
            transition: .3s;
          }
        }
      }
    }

    &.animation-on {
      .form {
        transform: scale(1);
        opacity: 1;
        animation: wiggle forwards .75s;
        animation-delay: .75s;
      }

      & > a {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    33% {
      transform: rotate(2.5deg);
    }
    67% {
      transform: rotate(-2.5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.display-9 {
  margin-bottom: 6em;

  @media screen and (max-width: $tablet) {
    margin-bottom: 2em;
  }
}

.display-10 {
  .animation-block {
    display: flex;
    margin-bottom: 2em;

    & > div {
      width: 50%;
      text-align: center;
      padding: 0 1.5em;

      img {
        max-height: 90vh;
      }

      &:first-child {
        position: relative;

        img {
          position: absolute;
          opacity: 0;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          max-width: 30em;
          max-height: 100%;

          @media screen and (max-width: $tablet) {
            max-width: 100%;
          }
        }
      }
    }

    &:nth-of-type(2n) {
      div:last-child {
        transform: translateX(-100%);
      }
    }

    &:nth-of-type(2n+1) {
      direction: rtl;

      div:last-child {
        transform: translateX(100%);
      }
    }
  }
}

.hash-tag {
  font-size: 128/16 + em;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: $tablet) {
    font-size: 5.5em;
  }

  @media screen and (max-width: $mobile-m) {
    font-size: 4.5em;
  }
}

.animation-block.fade-scale {
  opacity: 0;
  transform: scale(.8);
  transition: .75s;

  &.animation-on {
    opacity: 1;
    transform: scale(1);
  }
}

.animation-block.fade-left {
  opacity: 0;
  transform: translateX(-100%);
  transition: .75s;

  &.animation-on {
    opacity: 1;
    transform: translateX(0);
  }
}

img.responsive {
  @media screen and (max-width: $mobile-l) {
    width: auto !important;
  }
}

.hidden-lg {
  @media screen and (min-width: $tablet + 1) {
    display: none !important;
  }
}

.hidden-xs {
  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}

.mobile-wide {
  @media screen and (max-width: $mobile-l) {
    width: 200% !important;
    max-width: 200% !important;

    &:not(.display-2) {
      transition: margin-left 0s linear 0s, opacity .75s, transform .75s !important;
    }
  }
}

.dark {
  background: #000;
  color: #fff;

  .share:not(.regular) {
    .ya-share2__badge {
      background: none !important;
    }
  }

}

.share {
  text-align: center;

  @media screen and (max-width: $laptop-s) {
    font-size: 1.7em;
  }

  .ya-share2__container_size_m {
    font-size: 1em;
  }

  .ya-share2__container_size_m .ya-share2__icon {
    height: 2em;
    width: 2em;
    position: relative;
  }
}

