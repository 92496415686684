.panama-link {
  background: #ffe650;
  padding: 0.5em;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 12em;
  text-align: center;
  margin-left: -5em;
  transform: rotate(-45deg) translateY(2em);
  transform-origin: center center;
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
  transition: .3s;

  &:hover {
    background: #fdd900;
  }
}