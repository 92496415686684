/** SegoeUIRegular **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIRegular/segoeui.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIRegular/segoeui.woff') format('woff'),
  url('/assets/fonts/SegoeUIRegular/segoeui.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIRegular/segoeui.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** SegoeUIBold **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIBold/segoeuib.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIBold/segoeuib.woff') format('woff'),
  url('/assets/fonts/SegoeUIBold/segoeuib.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIBold/segoeuib.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

/** SegoeUIBold **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIBlack/SegoeUIBlack.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIBlack/SegoeUIBlack.woff') format('woff'),
  url('/assets/fonts/SegoeUIBlack/SegoeUIBlack.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIBlack/SegoeUIBlack.svg') format('svg');
  font-weight: 900;
  font-style: normal;
}

/** SegoeUISemiBold **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUISemiBold/seguisb.eot');
  src: local('☺'), url('/assets/fonts/SegoeUISemiBold/seguisb.woff') format('woff'),
  url('/assets/fonts/SegoeUISemiBold/seguisb.ttf') format('truetype'),
  url('/assets/fonts/SegoeUISemiBold/seguisb.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

/** SegoeUIItalic **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIItalic/segoeuii.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIItalic/segoeuii.woff') format('woff'),
  url('/assets/fonts/SegoeUIItalic/segoeuii.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIItalic/segoeuii.svg') format('svg');
  font-weight: normal;
  font-style: italic;
}

/** SegoeUILight **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUILight/segoeuil.eot');
  src: local('☺'), url('/assets/fonts/SegoeUILight/segoeuil.woff') format('woff'),
  url('/assets/fonts/SegoeUILight/segoeuil.ttf') format('truetype'),
  url('/assets/fonts/SegoeUILight/segoeuil.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

/** SegoeUIBoldItalic **/
@font-face {
  font-family: 'SegoeUIRegular';
  src: url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.eot');
  src: local('☺'), url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.woff') format('woff'),
  url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.ttf') format('truetype'),
  url('/assets/fonts/SegoeUIBoldItalic/segoeuiz.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?hu8mmx');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?hu8mmx#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?hu8mmx') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?hu8mmx') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?hu8mmx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right:before {
  content: "\e902";
}
.icon-left:before {
  content: "\e903";
}
.icon-left2:before {
  content: "\e904";
}
.icon-right2:before {
  content: "\e905";
}
.icon-right3:before {
  content: "\e900";
}
.icon-left3:before {
  content: "\e901";
}

